
body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.section-row1-submenu-user{
  position: fixed;
  padding: 15px;
  background: #f3f8fc;
  border-radius: 10px;
  right: 6%;
  top:54px;
  width: 189px;
  display: block;
  z-index: 999999;
}
.section-row1-submenu-user ul li{
  cursor: pointer;
  width: 100%;
  float: left;
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #c2c2c2;
  font-size: 18px;
  color: #3b4773;
}
.section-row1-submenu-user ul li:hover{
  background: #fcd25c;
}
.submenu-user{
  position: absolute;
  padding: 15px;
  background: whitesmoke;
  border-radius: 10px;
  right: 6%;
  bottom:86%;
  width: 189px;
  display: block;
  z-index: 999999;
}

.submenu-user ul li{
  cursor: pointer;
  width: 100%;
  float: left;
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #c2c2c2;
  font-size: 18px;
}
.submenu-user ul li:hover{
  background: #3981b2;
}

.up-arrow {
  border-color: whitesmoke transparent;
  border-style: solid;
  border-width: 0px 15px 20px 15px;
  height: 0px;
  width: 0px;
  position: absolute;
  right: 12px;
  top: -14px;
}
/* SLide start for trending */
.mySlides {display: none; padding: 20px 100px;}
#mySlides {display: block}
mySlides img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active1, .dot:hover {
  background-color: #000;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
/* slider finished */

.blur-text{
  filter:blur(3px);
}


.job-testimonial-left{
  width: 15%;
    float: left;
    transform: translate(6%, 0%);
}
.job-testimonial-right{
  width: 80%;
  float: right;
  color: #040404; 
  /* padding-right: 30px; */
}
#job-testimonial-padding{
  width:100%; 
  float:left; 
  padding:0 22px; 
}
/* ====for resister and login end ===== */

.job-Trending{
  background: whitesmoke;
  width: 100%;
  border-radius:10px;
  /* margin: 30px 0; */
  padding: 17px 80px 16px 80px;
  }
  .Trending-job-Left{
  width: 48%;
  float: left;
  background: #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  height: 240px;  
  }
  .jobListContainer{
  width: 100%;
  float: left;
  color: black;
  
  }
  .jobList-Left{
  display: none;
  width: 35%;
  float: left;
  position: relative;
  }
  .jobList-Left img{
  background: gray;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  }
  .jobList-Right{
  width: 100%;
  float: left;
  position: relative;
  height: 170px;
  }
  .jobList-Right h4{
  float: left;
  text-align: left;
  margin: 2px 0;
  color: #2f4672;
  padding: 10px 0;
  /* height: 65px; */
  }
  .jobList-Right p{
  width: 100%;
  float: left;
  /* padding: 10px 0 35px 0; */
  /* height: 100px; */
  color: #2f4672;
  }
  .jobList-Right button{
    text-align: center;
    width: 25%;
    padding: 10px;
    background: #1f79b5;
    float: right;
    border-radius: 50px;
    color: white;
    /* margin: 10px 40%; */
    /* font-weight: bold; */
    font-size: 14px;
    border: 1px solid #1f79b5;
    cursor: pointer;
  }
  .Trending-job-Right{
  width: 48%;
  float: right;
  background: #e5e5e5;
  border-radius: 15px;
  padding: 20px;
  height: 240px;
  position: relative;
  }
  .trending-job-slide{
  display: none;
  }
  #trending-job-slide0{
  display: block;
  }
  .trending-job-container {
  text-align: center;
  /* padding: 9px; */
  /* position: absolute; */
  top:50%;
  right:49%;
  width: 100%;
  float: left;
  }
  .trending-job-dot{
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 10px 2px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #3981b2;
  display:inline-block;
  transition: background-color 0.6s ease;
  }
  .trending-job-dot-showMore{
    cursor: pointer;
    height: 12px;
    width: 100%;
    margin: 10px 2px;
    background-color: transparent;
    display:inline-block;
  }
  #trending-job-dot0{
  background-color: #3981b2;
  
  }
  .active1, .trending-job-dot:hover {
  background-color: #3981b2;
  }
  /* ====for toast start======================== */
  #toast {
    visibility: hidden;
    max-width: 50px;
    height: 50px;
    /*margin-left: -125px;*/
    margin: auto;
    background-color: #3981b2;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    position: fixed;
    z-index: 1;
    left: 0;right:0;
    bottom: 50%;
    font-size: 17px;
    white-space: nowrap;
    z-index: 999999;
}
#toast #img{
	width: 50px;
	height: 50px;
  float: left;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  background-color: #3981b2;
  color: #fff;
  opacity: 0.8;
}
#toast #desc{

    color: #fff;
   
    padding: 16px;
    
    overflow: hidden;
	white-space: nowrap;
}

#toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 50%; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 50%; opacity: 1;}
}

@-webkit-keyframes expand {
  from {min-width: 50px} 
  to {min-width: 350px}
}

@keyframes expand {
  from {min-width: 50px}
  to {min-width: 350px}
}
@-webkit-keyframes stay {
  from {min-width: 350px} 
  to {min-width: 350px}
}

@keyframes stay {
  from {min-width: 350px}
  to {min-width: 350px}
}
@-webkit-keyframes shrink {
  from {min-width: 350px;} 
  to {min-width: 50px;}
}

@keyframes shrink {
  from {min-width: 350px;} 
  to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
  from {bottom: 50%; opacity: 1;} 
  to {bottom: 50%; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 50%; opacity: 1;}
  to {bottom: 50%; opacity: 0;}
}
/*  */
.react-drag-slider {
  overflow: hidden;
  position: relative;
}
.react-drag-slider__content {
    display: inline-flex;
    will-change: transform;
    width: auto;
  }

  .react-drag-slider__scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .react-drag-slider__rail {
    width: 100%;
    height: 100%;
    background-color: darkred;
  }

  .react-drag-slider__bar {
    height: 100%;
    background-color: #169927;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    will-change: transform;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  #searched-job{
    width: 45%;
    margin:0 5px;
    height: 240px;
    float: left;
}
  #searched-job-id{transform: translate(30%,490%);}

  
/* contact-us start */
.contactUsLeft{
  width: 45%;
  float: left;
}
.contactUsRight{
  width: 45%;
  float: right;
  margin: 10px 0;
}
.contactUsLeftInputContainer {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}
.contactUsLeft input[type=text]{
  width:100%;
  float: left;
  color: black;
  border-radius:0px;
  background: #fff;
  padding: 5px 0px;
  margin-bottom: 0px;
  border: none;
  border-bottom:1px solid #00bcd4;
}
.contactUsRight textarea {
  width:100%;
  height: 118px;
  float: left;
  color: black;
  border-radius:15px;
  background: #fff;
  padding: 10px 5px;
  margin-bottom: 10px;
  border:1px solid #00bcd4;
}
.contactUsLeft input[type=text],.contactUsRight textarea{font-size: 10px;font-weight: normal;}
.contactUsIcon {
  padding: 10px 0;
  background: white;
  color: orange;
  min-width: 30px;
  text-align: center;
}
.contactUsSubmit{
  width: 100%;
  float: left;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}


/* contact-us end */
/*  */
@media only screen and (max-width:1064px){
  .jobList-Left,.jobList-Right,.jobList-Right h4{width: 100%;text-align:center}
  #searched-job{width: 100%;}
  #searched-job-id{transform: translate(41%, 480%);}
  #Login-box{width: 35%;}
  #job-testimonial-padding{padding: 0px;}
  .jobList-Left{transform: translate(0%)}
  .Trending-job-Left,.Trending-job-Right{height: 404px;}
}
@media only screen and (max-width:875px){
  .contactUsLeft{width: 100%;float: left;}
  .contactUsRight{width: 97%;float: left;margin: 10px 23px;}
  .ContactUs-rootStyle-1{top:"7%"}
  .job-testimonial-left{margin: 0;}
  #jobTestimonial-author{margin-top: 40px} 
  #Login-box{width: 50%;bottom: 0%;top:13%;height: 500px;}
  .jobList-Left{transform: translate(0%)}
  .jobList-Right button{float: left;margin: 50% 30%;width: 50%}
  .job-Trending{padding: 17px 10px;}
  .header-logo{width: 20%;}
  .header-nav{width: 80%;}
  .stats-block{width: 30%;margin: 50px 0;}

  
}
@media only screen and (max-width:800px){


}
@media only screen and (max-width:700px){
  #Mu-contact-us-box{width: 400px;}
  .Trending-job-Left{width: 100%;margin: 20px 0;}
  .Trending-job-Right{width: 100%} 
  .jobList-Right button{margin: 20% 30%;}

}
@media only screen and (max-width:465px){
  #Login-box{width: 60%;left: 20%;}
  .contactUsRight{width: 90%;}


}
/* jobs */
@media only screen and (max-width:675px){
  .searchBar-text{width: 100%;float: left;border-bottom: 1px solid #646464;border-right: none}
  .searchBar-text-icon{width: 100%;float: left;margin-top: 10px;text-align: center;}
  #loginSignUp-box{width: 70%;margin: 0 10%;}
  #back-login-panel{width: 60%;right: 15%}
}
@media only screen and (max-width:500px){
  .jobList-Right button{margin: 10% 30%;}
  #jobTestimonial-author{margin-top: 80px!important}
  .job-Trending{padding: 10px}
  #job-testimonial-padding{padding: 0px;}
  .mySlides {padding: 20px 0px;}
  

}
@media only screen and (max-width:400px){
#loginSignUp-box{width: 100%;margin: 0 0%;}
}