* {
  scroll-behavior: smooth;
  margin: 0px;
  padding: 0px;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Titillium Web", sans-serif;
}
p {
  font-family: "Muli", sans-serif;
}
.App {
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.table-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.table-clinical-validation {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

table {
  border: 1px solid black;

  width: 450px;
  height: 130px;
}

th {
  border-bottom: 1px solid black;
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;

  color: #f77200;
}

td {
  text-align: center;
  height: 20px;
  /* padding-left: 5px; */
}
.td-left {
  text-align: left;
  padding-left: 5px;
}
.theme-change-container {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 15%;
  background: #1a2127;
  border-radius: 50px 0 0px 50px;
  padding: 10px;
  z-index: 999;
  cursor: pointer;
  transition: width 0.2s;
  -webkit-transition: width 0.2s;
}

.theme-change-container:hover {
  width: 100px;
}
.theme-change-container .color-box-container {
  display: none;
  width: 100%;
  float: left;
  padding: 10px;
}
.theme-change-container:hover .color-box-container {
  display: block;
}
.color-box-container ul li {
  width: 10px;
  float: left;
  height: 10px;
  margin-left: 5px;
  background: orange;
  cursor: pointer;
}
.theme-change-container:hover #setting-icon {
  display: none;
}
.sections {
  width: 100%;
  height: 600px;
  position: relative;
}
.section-row1 {
  background: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 600px;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}
.banner-slider {
  width: 100%;
  float: left;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 0;
  /* background: #7d7d7d;
  border: 1px solid #212121; */
}
.carousel-arrow li {
  width: 10px;
  height: 10px;
  border-radius: 0;
  /* background: #7d7d7d;
  border: 1px solid #212121; */
}

.item {
  background: #333;
  text-align: center;
}
.control-buttons {
  text-align: center;
}
.carousel-control {
  z-index: 11;
}
.transition-timer-carousel-progress-bar {
  height: 3px;
  background-color: #5cb85c;
  width: 0%;
  margin: 0px 0px 0px 0px;
  border: none;
  z-index: 11;
  position: relative;
}
/* @-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
} */

/* @keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
} */
/* @-webkit-keyframes blur {
  from {
    -webkit-filter: blur(10px);
  }
  to {
    -webkit-filter: blur(0px);
  }
}

@keyframes blur {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0px);
  }
} */
.carousel-inner .item > img {
  -webkit-animation: zoom 20s;
  animation: zoom 20s, blur 5s;
}
.progress-bar {
  width: 100%;
  float: left;
}
.transition-timer-carousel-progress-bar {
  background: #57ca85;
  height: 5px;
}

.stickymenu {
  width: 100%;
  float: left;
  position: fixed;
  background: #fff;
  /* background:#f3f8fc; */
  /* #dfe1e4; */
  /* height: 70px; */
  z-index: 99999999;
  opacity: 1;
  /* visibility: hidden; */
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
}
.job-Testimonial {
  background: whitesmoke;
  width: 100%;
  height: 300px;
  border-radius: 10px;
  margin: 30px 0;
  position: relative;
  color: black;
  padding: 20px;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
/* slider finished */
.job-Testimonial h2 {
  text-align: center;
  margin: 10px 0;
}
.job-Testimonial-slide {
  background: #e5e5e5;
  border-radius: 10px;
  height: 180px;
}
.job-testimonialslideshow-container {
  position: relative;
  /* margin-top: 10%; */
}
.job-testimonial-mySlides {
  display: none;
  padding: 0 55px;
  text-align: center;
}
.job-testi-dot-container {
  text-align: center;
  padding: 9px;
  position: absolute;
  top: 80%;
  right: 50%;
}
.job-testimonial-dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 10px 2px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #3981b2;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active1,
.job-testimonial-dot:hover {
  background-color: #3981b2;
}
#job-testi-mySlides1 {
  display: block;
}
.job-containerTestimonial {
  width: 15%;
  padding: 10px;
  transform: translate(6%, 0);
}
.job-containerTestimonial img {
  float: left;
  margin-right: 20px;
  border-radius: 50%;
  /* margin:-6%; */
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.job-containerTestimonial h4 {
  text-align: center;
  float: left;
  font-size: 15px;
  width: 130px;
  padding: 0;
  margin: 0 20px 0 0;
}
.job-containerTestimonial p {
  text-align: center;
  float: left;
  font-size: 15px;
  width: 130px;
  padding: 0;
  margin: 0 15px 0 0;
}

.job-Qtext {
  float: right;
  width: 50%;
  margin: 3% 100px;
}
.job-Qtext q {
  width: 100%;
  text-align: left;
  /* margin-left: 40%; */
}
.job-authorClass {
  color: #3981b2;
  font-size: 20px;
  font-weight: normal;
  text-align: right;
  float: right;
  margin: -70px 25%;
}
.wrapper {
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;
}
.publication-wrapper {
  width: 60%;
  max-width: 1180px;
  min-height: 850px;
  margin: 0 auto;
}
.resIcon {
  display: none;
  color: #000;
}
#menu {
  position: absolute;
  width: 200px;
  background: #fff;
  /* #0d2e2d; */
  opacity: 1;
  right: 1px;
  text-align: right;
  z-index: 9999;
  cursor: pointer;
}
#menu ul li {
  padding: 5px 10px;
  cursor: pointer;
}
#menu ul li a {
  text-decoration: none;
  color: black; /* #b6c6ce; */
  padding: 10px;
  display: block;
}
#menu ul li:hover {
  /* border-bottom: 1px solid #455f79; */
  background: #303d48;
  color: whitesmoke;
  /* #1f466e */
}
#menu ul li a:hover {
  color: whitesmoke;
}
#submenu {
  position: absolute;
  width: 62px;
  background: grey;
  opacity: 1;
  /* right: 61px; */
  float: left;
  z-index: 9999;
  /* clip-path: polygon(50% 0%, 100% 22%, 99% 100%, 75% 100%, 25% 100%, 0 100%, 0 21%); */
}
#submenu ul li {
  padding: 5px 10px;
}
#submenu ul li a {
  text-decoration: none;
  color: white; /* #b6c6ce; */
  padding: 10px;
  display: block;
}
#submenu ul li:hover {
  /* border-bottom: 1px solid #455f79; */
  background: whitesmoke;
  /* #1f466e */
}
.header-top {
  width: 100%;
  float: left;
  height: 70px;
  /* padding: 25px 0; */
}
.header-top .header-logo {
  text-align: left;

  width: 30%;
  display: inline-block;
  position: absolute;
  left: 95px;
  top: 10px;
  /* height: 70px; */
}
.header-logo .logo-img {
  width: 200px;
  /* height: 140px; */
  /* margin-left:60px; */
  /* margin-top:-60px; */
  /* padding: 15px; */
}
.header-nav {
  width: 70%;
  float: right;
  text-align: center;
  margin-top: 20px;
  text-align: right;
}

.tooltips {
  display: inline-block;
  margin-left: 50px;
  top: 5px;
}
.tooltips a {
  text-decoration: none;
  text-decoration-line: none;
  /* color:white;   */
  color: #304773;
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.tooltips .tooltiptext {
  margin: 2px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.header-bottom {
  top: 10%;
  /* left: 5%; */
  position: absolute;
  width: 100%;
  /* padding-top: 60px; */
  float: left;
  color: white;
  z-index: 99;
}
#header-bottom-1 {
  left: 2%;
}
#header-bottom-2 {
  /* right: 0%;
  float: right;
  top: 15%; */
  left: 2%;
}
#header-bottom-3 {
  left: 2%;
  /* float: left;
  top: 45%; */
}
.banner-text {
  width: 100%;
  text-align: center;
  /* width:100%; */
  font-weight: normal;
  color: #000;
  filter: blur(0px);
  margin-left: 8px;
  float: left;
  /* margin-left: 40%; */
  font-size: 21px;
  /* line-height: 1; */
  margin-top: 30px;
}
.banner-button {
  margin: 30px 10px;
  color: #304773;
  background: #fcd25c;
  padding: 10px 20px;
  text-align: center;
  float: left;
  border-radius: 8px;
  border: 1px solid #fcd25c;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.banner-button a {
  text-decoration-line: none;
}
.banner-form {
  display: none;
  width: 600px;
  position: absolute;
  top: 10%;
  left: 30%;
}
.banner-text h1 {
  font-size: 35px;
  font-weight: 500;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 0px;
  padding: 0 0 10px 0;
  color: #fff;
  opacity: 0.8;
  line-height: 40px;
}
.banner-text p {
  margin-top: 30px;
}
.banner-text1 {
  width: 50%;
  font-weight: normal;
  color: white;
  /* margin-bottom: 20%; */
  float: left;
  margin-top: 10%;
}
.section-clients {
  width: 100%;
  background-color: #efefef;
  float: left;
  padding: 10% 0 85px 0;
  text-align: center;
  position: relative;
  /* height: 650px; */
}
/* section-row2 start */
.section-about-us {
  width: 100%;
  background-color: white;
  float: left;
  padding: 10% 0 85px 0;
  text-align: center;
  position: relative;
  /* height: 650px; */
}
.row5-h1 {
  text-align: center;
  color: #4d4d4d;
  font-size: 40px;
  margin-bottom: 50px;
}
#About-Us {
  padding: 10% 0px;
}
.About-us-head {
  /* color: #323232; */
  color: #f77200;
  text-align: center;

  /* width: 17%; */
  /* margin-left: 42%;  */
}
.About-us-headH1 {
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  /* letter-spacing: 4px; */
  padding: 0 0 10px 0;
  color: #000;
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
}

.about-us-stats {
  width: 100%;
  float: left;
}
.about-us-slides {
  width: 100%;
  /* position: relative; */
  float: left;
  margin-bottom: 20px;
}
.about-us-slidetext {
  float: left;
  width: 50%;
  position: relative;
}
.stats-block {
  height: 200px;
  width: 42%;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin: 3% 25px 0 25px;
  float: left;
  padding: 20px;
  border-radius: 15px;
}
.stats-block h3 {
  color: #fff;
  font-size: 12px;
}
.stats-block h6 {
  width: 100%;
  color: #f77400;
  margin: 10px 0px;
  font-weight: 100;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 4px;
  font-family: "Titillium Web", sans-serif;
  line-height: 1.7em;
}
.stats-block p {
  /* text-align: justify; */
  font-size: 16px;
  color: #fff;
  font-family: "Muli", sans-serif;
  line-height: 1.65em;
}
/* section4 --new start*/
.section4-content {
  width: 100%;
  float: left;
  font-size: 14px;
  padding: 30px 0;
  font-family: "Muli", sans-serif;
  color: #fff;
}
.section4-content p {
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  line-height: 1.65em;
}
.section4-content h5 {
  font-weight: normal;
  font-size: 18px;
}
/* section4 --new end*/
.section4-content h2 {
  color: #0e0e0e;
  text-align: left;
  margin-bottom: 20px;
  font-weight: normal;
}
.slick-prev:before,
.slick-next:before {
  color: #f77200 !important;
}
.section4-content-p {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
  color: black;
}
.section5-counter {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 60px;
}
.counter-main {
  font-size: 80px;
  /* color: #1f79b5; */
  font-weight: bold;
  font-size: 50px;
}

.section5-counter ul {
  width: 80%;
  /* float: left; */
  margin: 0 auto;
}
.section5-counter ul li {
  list-style-type: none;
  width: 33%;
  float: left;
}
.section5-counter ul li span {
  text-transform: uppercase;
  margin-top: 10px;
  display: block;
  color: #f5f5f5;
}
.row5-h1 {
  text-align: center;
  color: #4d4d4d;
  font-size: 40px;
  margin-bottom: 50px;
}
.testi-slideshow-container {
  margin-top: 15%;
}
.testi-mySlides {
  display: none;
  padding: 0 55px;
  text-align: center;
}

#testi-mySlides1 {
  display: block;
}
#section2-testi-dot-container {
  right: 65%;
  top: 98%;
}
.testi-dot-container {
  text-align: center;
  padding: 9px;
  position: absolute;
  top: 98%;
  right: 50%;
}

.testi-dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 10px 2px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fbca4f;
  display: inline-block;
  transition: background-color 0.6s ease;
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  width: auto;
  color: #fbca4f;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
  display: inline-block;
  margin-top: 2%;
}
/* Position the "prev & next button" to the right */
.prev {
  position: absolute;
  top: 95%;
  right: 85%;
}
.next {
  position: absolute;
  top: 95%;
  right: 45%;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  /* background-color: #1f79b5; */
}
/* Add a background color to the active dot/circle */
.active,
.testi-dot:hover {
  background-color: #fbca4f;
}

/* Add an italic font style to all quotes */
.testi-mySlides-p {
  width: 100%;
  float: left;
  font-size: 15px;
  font-weight: normal;
  /* color:#4d4d4d; */
  color: #fff;
  text-align: left;
  position: relative;
}
.testi-mySlides-p p {
  text-align: left;
  width: 100%;
  /* margin-top: 20px; */
  line-height: 22px;
  font-size: 15px;
  font-weight: normal;
}

.testi-mySlides-p h2 {
  text-align: left;
  width: 100%;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

/* Add a blue color to the author */

.containerx {
  width: 35%;
  float: left;
  margin-left: 10%;
}
.containerx img {
  float: left;
  margin-right: 20px;
  border-radius: 5%;
  /* margin:-6%; */
  width: 100%;
}

.containerx span {
  font-size: 20px;
  margin-right: 15px;
}

@media (max-width: 800px) {
  .table-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .table-clinical-validation {
    width: 95%;
    /* height: auto; */
    /* display: flex;
    justify-content: center; */
    /* align-items: center; */
  }

  .table-clinical-validation table {
    border: 1px solid black;
    width: 70px;
    height: 200px;
  }

  .table-clinical-validation th {
    border-bottom: 1px solid black;
    padding-left: 5px;

    color: #f77200;
  }

  td {
    text-align: center;
    padding-left: 5px;
  }
  .containerx img {
    margin: auto;
    float: none;
    display: block;
  }
}
/* section-row 5 */

#Sign-In {
  color: white;
  text-align: center;
  font-weight: bold;
  /* width: 38%; */
  /* margin-left: 30%;  */
  font-size: 18px;
}
#Sign-In h1 {
  /* font-size: 40px; */
  /* margin-bottom: 20px; */
  text-decoration: underline;
  text-underline-position: under;
}
.section-core-value-bottom-line {
  display: none;
  /* border-bottom: 1px solid grey; */
  display: inline-block;
  width: 60px;
  margin-bottom: 15px;
}
.section3-text {
  /* display: none; */
  float: left;
  text-align: left;
  padding: 0px 0 0 60px;
  /* margin-top:6%;  */
  /* margin-left: auto; */
  /* width: 50%; */
}
/* job search for role */
#job-role-container {
  display: block;
  position: absolute;
  top: 127px;
  width: 350px;
  border: none;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  color: #304773;
  overflow-y: scroll;
  max-height: 400px;
}
#job-role-container ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#job-role-container ul li {
  cursor: pointer;
  padding: 10px 20px;
}
#job-role-container ul li:hover {
  background-color: #ddd;
}
/* end */
.searchBar-text input[type="text"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-bottom: 1px solid white;
  box-sizing: border-box;
  margin-bottom: 16px;
  color: #304773;
  background-color: transparent;
  font-size: 16px;
  font-family: Helvetica;
}
#name {
  margin-bottom: 30px;
}
#email {
  margin-bottom: 40px;
}
/* input::placeholder{
    color:white;
} */
#subject {
  height: 160px;
  width: 85%;
  margin-top: 9%;
  border-radius: 10px;
  background: #aeaeae;
  border: 1px;
  color: black;
  font-family: Helvetica;
  /* src: url(./helvetica/Helvetica.ttf); */
}
#subject::placeholder {
  color: #5a5a5a;
  text-align: left;
  padding-left: 1%;
}
/* material ui */
#mu-name,
#mu-email,
#mu-mobile,
#mu-msg {
  width: 200px;
  margin: 10px 0;
  padding: 0;
  color: black;
}
#Mu-contact-us-box {
  width: 600px;
}
.MuiPaper-rounded-20 {
  border-radius: 15px;
}
/* end */
input[type="submit"] {
  background-color: #1f79b5;
  color: white;
  padding: 20px 40px;
  border: none;
  font-size: 20px;
  font-weight: normal;
  border-radius: 10px;
  cursor: pointer;
  font-family: Helvetica;
  /* src: url(./helvetica/Helvetica.ttf); */
  /* margin-left: 40%; */
}

input[type="submit"]:hover {
  background-color: #1f79b5;
}
.section3-subject {
  display: block;
  float: left;
  text-align: left;
  padding: 0px 0 0 120px;
  width: 100%;
}
/* services */
.section-Services {
  padding: 5% 0;
  height: fit-content;
  background-color: white;
  width: 100%;
  float: left;
  position: relative;
  /* height: 650px; */
}
.Services-Section-Head {
  color: #212121;
  text-align: center;
  font-weight: normal;
}
.Services-Section-Head-bottom-line {
  border-bottom: 1px solid #bdb3b3;
  display: inline-block;
  width: 125px;
  margin-bottom: 15px;
}
.Services-Section-Head p {
  font-size: 14px;
  color: #636262;
  font-family: "Muli", sans-serif;
}
.service-slider {
  padding: 30px;
}
.slider-image-container {
  background: transparent;
  position: relative;
  width: 80% !important;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  border: 2px solid #747373;
  height: 520px;
  min-width: 200px;
}
@media only screen and (max-width: 600px) {
  .publication-wrapper {
    width: 100%;
    /* max-width: 1180px;
    min-height: 850px;
    margin: 0 auto; */
  }
  .slider-image-container {
    height: 530px;
  }
  .section4-content p {
    font-size: 15px;
  }
}
.slider-image-container a {
  text-decoration: none !important;
}
.slider-image-container .image {
  width: 100%;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 5px 5px 0 0;
}
.slider-image-container .slider-post-details {
  padding: 10px;
}
.slider-image-container .slider-post-details h6 {
  width: 100%;
  margin: 5px 0px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.4;
  text-align: left;
  font-size: 14px;
  color: #f77401;
  font-family: "Titillium Web", sans-serif;
}
.slider-image-container .slider-post-details p {
  width: 100%;
  margin: 0px;
  font-size: 14px;
  text-align: left;
  color: #efefef;
  font-weight: normal;
  line-height: 1.65em;
  font-family: "Muli", sans-serif;
}
.slider-image-container p figure {
  display: none;
}
.slider-image-container p h3 {
  font-size: 12px;
}

.slider-image-container .middle {
  background: #000;
  position: absolute;
  height: 99%;
  width: 320px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.slider-image-container .middle-left {
  width: 32%;
  float: left;
  background: transparent;
  margin: 30% 0;
  margin-left: 10%;
  border: 2px solid #fff;
}
.slider-image-container .middle-right {
  width: 32%;
  float: right;
  background: transparent;
  margin: 30% 40px;
  border: 2px solid #fff;
}

.slider-image-container:hover .image {
  /* opacity: 0.3; */
}

.slider-image-container:hover .middle {
  opacity: 0.7;
}

.slider-image-container .text {
  color: #ffffff;
  background-color: transparent;
  font-size: 15px;
  font-family: Dosis, Arial;
  font-weight: 600;
  font-style: normal;
  line-height: 30px;
  text-align: center;
  text-transform: none;
  padding: 0px 10px 0px 10px;
}
/* services-end */

/* Testimonial-section */
.section-Testimonial {
  background: #fff;
  width: 100%;
  float: left;
  padding: 6% 0 1%;
  text-align: center;
  position: relative;
  /* height: 650px; */
}
.slick-dots li.slick-active button:before {
  color: #efefef !important;
}
.slick-dots li button:before {
  color: #efefef !important;
}
.team-sub-section {
  padding: 6% 0;
  width: 100%;
  float: left;
  background: #fff;
  height: auto;
}
.advisory-open-button {
  width: 30px;
  /* height: 20px; */
  float: center;
  padding: 10px;
  font-size: 16px;
  font-family: "muli", sans-serif;
  background: transparent;
  border: 2px solid #f77200;
  border-radius: 15%;
  color: #000;
  text-decoration-line: none;
}
.advisory-container {
  width: 100%;
  display: flex;
}
.advisory-tag {
  width: 80%;
}
.advisory-container h3 {
  width: 80%;
  text-align: left;
  color: #000;
  margin: 15px 0;
  font-size: 16px;
}
.advisory-button {
  width: 150px;
  float: right;
  padding: 10px;
  font-size: 16px;
  font-family: "muli", sans-serif;
  background: transparent;
  border: 2px solid #f77200;
  border-radius: 5px;
  color: #000;
  text-decoration-line: none;
}
.advisory-button:hover {
  background: #f77200;
  color: #efefef;
}
.Testimonial-head {
  color: #0e0e0e;
  text-align: center;
}
.Testimonial-headH1 {
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 4px;
  padding: 0 0 10px 0;
  color: #000;
  margin: 0;
}
.Testimonial-head-bottom-line {
  border-bottom: 2px solid white;
  display: inline-block;
  width: 260px;
  margin-bottom: 15px;
}
.testimonialslideshow-container {
  position: relative;
  /* margin-top: 10%; */
}
.testimonial-mySlides {
  display: none;
  padding: 0 55px;
  text-align: center;
}
.site-testimonial {
  width: 100%;
  float: left;
  padding: 20px 0;
}
.site-testimonial-left {
  text-align: center;
  width: 100%;
  float: left;
  border-radius: 15px;
  /* border:2px solid orange; */
  /* margin: 0 20px; */
  padding: 0 20px;
  color: #444;
  /* height: 480px; */
}
.site-testimonial-left .slick-slide img {
  display: unset !important;
}
.site-testimonial-right {
  width: 40%;
  float: left;
  border-radius: 15px;
  padding: 10px;
  /* border:2px solid orange;  */
  padding: 40px;
  color: #444;
  height: 480px;
}
.testi-dot-container {
  text-align: center;
  padding: 9px;
  position: absolute;
  top: 85%;
  right: 50%;
}
.testimonial-dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 10px 2px;
  background-color: #444;
  border-radius: 50%;
  border: 2px solid #444;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active,
.testimonial-dot:hover {
  background-color: #000;
}
#testi-mySlides1 {
  display: block;
}
.containerTestimonial {
  width: 30%;
  padding: 0 0 0 70px;
}
.containerTestimonial img {
  float: left;
  margin-right: 20px;
  border-radius: 50%;
  /* margin:-6%; */
  width: 200px;
  height: 200px;
  vertical-align: middle;
}

.containerTestimonial span {
  font-size: 20px;
  margin-right: 15px;
}
.Qtext {
  float: right;
  width: 50%;
  margin: 3% 100px;
}
.Qtext q {
  color: #444;
  width: 100%;
  text-align: left;
  /* margin-left: 40%; */
}
.author {
  color: #1f79b5;
  font-size: 20px;
  font-weight: normal;
  text-align: right;
  float: right;
  margin-top: 60px;
}

/* other team design */
.team-other-container {
  width: 100%;
  float: left;
  padding: 20px 0;
}
.team-other-container ul {
  width: 100%;
  display: flex;
}
.team-other-container ul li {
  width: 25%;
  /* margin: 0 10px; */
}
.team-other-container .social-media-icon {
  display: flex;
  justify-content: center;
}
.team-other-container .social-media-icon li {
  /* width: 20%; */
  float: left;
  padding: 2px 5px;
  margin: 0;
  list-style: none;
  /* margin: 6px 12px 5px -2px; */
  height: 25px;
  width: 12px;
  background: transparent;
  border-radius: 15%;
  margin: 0 10px;
}
/* testimonial section end */
/* CLient-Section */
.section-Clients {
  background: #101010;
  width: 100%;
  float: left;
  padding: 10px 0;
  text-align: center;
}
.client-list-slide-container {
  width: 100%;
  float: left;
  padding-top: 60px;
}
.section-Clients h3 {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 3px;
  font-family: "Titillium Web", sans-serif;
  color: #aeaeae;
  text-transform: uppercase;
  margin: 10px 0 20px 0;
}
.section-Clients p {
  font-size: 15px;
  font-family: "Muli", sans-serif;
  color: #636262;
  line-height: 16px;
  margin: 7px 0 15px 0;
}

#Contact-Us .col-container {
  display: flex;
  justify-content: space-between;
}

#Contact-Us .col1-left {
  max-width: 400px;
}

.section-Clients .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.news-lettet-container {
  width: 100%;
  float: left;
}
.news-lettet-container form {
  border: none;
  font-family: Arial;
}

.news-lettet-container .container {
  width: 100%;
  float: left;
  /* padding: 20px; */
  background-color: transparent;
  text-align: center;
}

.news-lettet-container input[type="text"],
input[type="submit"] {
  width: 100%;
  padding: 6px 15px;
  margin: 15px 0;
  display: inline-block;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  color: #474747;
  letter-spacing: 3px;
  font-size: 15px;
  font-family: "Muli", sans-serif;
  border-radius: 1em;
}
.news-lettet-container input[type="text"]::placeholder {
  color: #474747;
  letter-spacing: 3px;
  font-size: 13px;
  font-family: "Muli", sans-serif;
}
.news-lettet-container label {
  width: 60%;
  float: left;
  color: #f1f1f1;
  letter-spacing: 3px;
  font-size: 15px;
  font-family: "Muli", sans-serif;
}
.news-lettet-container input[type="checkbox"] {
  margin-top: 16px;
}

.news-lettet-container input[type="submit"] {
  background-color: transparent;
  color: #f2f2f2;
  border: 2px solid #f2f2f2;
  border-radius: 1em;
}

.news-lettet-container input[type="submit"]:hover {
  background-color: white;
  color: #212121;
  border: 2px solid #212121;
  opacity: 0.8;
}
/* section-7 end */
.slick-dots li button:before {
  font-size: 15px !important;
}

/* section-row8 */
.section-contact {
  padding: 8% 0 0px 0;
  width: 100%;
  background-color: rgb(33, 32, 32);
  border-bottom: 1px solid #484848;
  float: left;
}
.Contact-head {
  color: #212121;
  text-align: center;
}
.Contact-headH1 {
  padding: 0 0 5px 0;
  font-size: 30px;
  font-weight: bold;
}
.Contact-head-bottom-line {
  border-bottom: 2px solid white;
  display: inline-block;
  width: 160px;
  margin-bottom: 15px;
}
.topicon {
  display: none;
  bottom: 8%;
  right: 35px;
  float: left;
  opacity: 0.8;
  border-radius: 1px;
  z-index: 99;
  position: fixed;
  background: #f67401;
  padding: 20px;
  width: 50px;
  height: 50px;
  color: #212121;
  cursor: pointer;
}

.topicon i {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col2 img {
  margin-right: 10px;
  width: 6%;
}
.col2 ul {
  width: 100%;
  float: left;
  margin-top: 0px;
}
.col2 ul li {
  list-style-type: none;
  width: 15%;
  float: left;
  margin: 5px;
}
.socialiconlist {
  width: 60%;
  /* margin-right: 250px; */
  float: left;
}
.social-icon2 li img {
  background: grey;
  border-radius: 10px;
  filter: grayscale(100%);
  cursor: pointer;
  width: 120%;
}
.social-icon2 li img:hover {
  filter: grayscale(1%);
  background: #1f79b5;
}
.col2 ul li img {
  background: grey;
  border-radius: 10px;
  filter: grayscale(100%);
  cursor: pointer;
  width: 100%;
}
.col2 ul li img:hover {
  filter: grayscale(1%);
  background: #1f79b5;
}
.col-container {
  width: 100%;
  float: left;
  padding: 80px 0;
}
.col1-left {
  width: 50%;
  float: left;
}
.col2 {
  color: #212121;
  text-align: center;
  width: 50%;
  float: left;
  /* margin-top: 60px; */
  padding: 40px 20px;
}
.col2-icons {
  display: inline-block;
  margin-bottom: 5px;
  background: #f77200;
  height: 50px;
  width: 50px;
  padding-top: 8px;
  color: #f0f0f0;
  border-radius: 50px;
  font-size: 22px;
}
.arrow-icons-right {
  /* display: inline-block; */
  margin-bottom: 5px;
  margin-left: auto;
  background: transparent;
  height: 100px;
  width: 100px;
  z-index: 1000;
  padding-top: 8px;
  padding-right: 100px;
  color: #f0f0f0;
  border-radius: 50px;
  font-size: 100px;
}
.arrow-icons-left {
  /* display: inline-block; */
  margin-bottom: 5px;
  margin-right: auto;
  background: transparent;
  z-index: 1000;
  height: 100px;
  width: 100px;
  padding-top: 8px;
  padding-right: 100px;
  color: #f0f0f0;
  border-radius: 50px;
  font-size: 100px;
}
.col2-email {
  display: inline-block;
  width: 100%;
}
#col2-email-img {
  width: 10%;
  /* float: left; */
  vertical-align: middle;
}
#col2-email-p {
  width: 85%;
  float: right;
  font-size: 15px;
}
.col2 h2 {
  color: #feffff;
  font-size: 25px;
  text-align: center;
  font-weight: normal;
}
.col2 h3 {
  font-size: 11px;
  font-weight: normal;
  font-weight: bold;
  margin: 10px 0 20px 0;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 4px;
  color: #f0f0f0;
}
.col2 ul {
  margin-bottom: 10px;
  width: 100%;
  float: left;
}

.col2-text1 {
  text-decoration: none;
  width: 33%;
  float: left;
  margin-top: 20px;
  color: #212121;
}
.col2-text2 {
  text-decoration: none;
  width: 45%;
  float: left;
  margin-top: 20px;
  color: #212121;
}
.col2-text1 img {
  vertical-align: middle;
}
.col2-text2 img {
  vertical-align: middle;
}
.col2-text3 img {
  vertical-align: middle;
}
.col2-text1 p {
  font-size: 13px;
  color: #636262;
}
.col2-text2 p {
  font-size: 13px;
  color: #aeaeae;
}

.col2-text3 {
  text-decoration: none;
  width: 45%;
  float: left;
  margin-top: 20px;
  color: #212121;
}
.col2-text3 p {
  font-size: 13px;
  color: #aeaeae;
}
.col2-text4 {
  display: none;
  width: 20%;
  float: right;
  margin-top: 20px;
  /* text-align:left; */
}
#mobile-view-col2-email {
  display: none;
}
#desktop-view-col2-email {
  display: none;
}
.col2-text4 p {
  font-size: 13px;
  color: #feffff;
}
.col2-left-text p {
  text-align: left;
  margin-top: 10px;
  color: #aeaeae;
  font-size: 14px;
  font-weight: normal;
}
.col2-left-image {
  width: 16%;
  float: left;
}

.col3 {
  color: #feffff;
  width: 35%;
  float: right;
  text-align: left;
  margin-top: 60px;
  font-size: 12px;
  font-weight: normal;
}
.col3 h2 {
  font-size: 18px;
  font-weight: normal;
}
.col3 p {
  font-size: 14px;
  font-weight: normal;
  line-height: 23px;
}
.social-icon1 {
  display: block;
}
.social-icon2 {
  display: none;
}
.social-icon-container {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;

  padding: 10px;
}
.social-icon-container ul {
  width: 20%;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.social-icon-container ul li {
  /* width: 20%; */
  float: left;
  padding: 2px 5px;
  margin: 0;
  list-style: none;
  /* margin: 6px 12px 5px -2px; */
  height: 25px;
  width: 25px;
  background: #efefef;
  border-radius: 50%;
  margin: 0 10px;
}
.social-icon-container ul li a {
  transition: linear 0.3s;
  font-size: 14px;
  line-height: 14px;
  color: #a8a8a8;
}

#copyright {
  font-size: 14px;
  color: #a9a7a7;
  margin: 10px 0;
  cursor: pointer;
}

.privacy-policy-page-body,
.terms-of-use-page-body,
.refund-policy-page-body,
.clinical-validation-page-body,
.resources-page-body {
  padding-top: 85px;
  min-height: 940px;

  /* padding: 10% 0 85px 0; */
}
.privacy-policy-page-h1,
.terms-of-use-page-h1,
.refund-policy-page-h1,
.resources-page-h1 {
  text-align: center;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 0 0 15px 0;
  color: #f77200;
}
.resources-page-heading {
  text-align: center;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  font-size: 24px;
  padding: 20px 0 0 0;
  color: #f77200;
}

.clinical-validation-page-h1 {
  text-align: center;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 0 0 0 0;
  color: #f77200;
}
.privacy-policy-page-sub-headings,
.terms-of-use-page-sub-headings,
.refund-policy-page-sub-headings {
  margin-top: 40px;
  text-align: left;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 0 0 15px 0;
  color: #f77200;
}
.clinical-validation-page-sub-headings {
  /* margin-top: 10px; */
  text-align: left;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 0 0 0px 0;
  /* color: #f77200; */
}
.clinical-validation-page-sub-headings-1 {
  /* margin-top: 10px; */
  text-align: left;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 20px 0 15px 0;
  color: #f77200;
}
.content-container-clinical-study {
  width: 100%;
  display: flex;

  flex-direction: row;
  /* background: red; */
}
.image-container {
  width: 50%;
  /* background-color: orange; */
  display: flex;
}
.image-container img {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.text-container-clinical-study {
  /* border: 10px;
  padding: 5px; */
  margin: 10px;
}
.content-container-clinical-study p {
  font-size: 22px;
}
.publication-page-sub-headings {
  /* margin-top: 10px; */
  text-align: center;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 0 0 0px 0;
  color: #f77200;
}
.privacy-policy-page-sub-sub-headings,
.terms-of-use-page-sub-sub-headings {
  margin-top: 23px;
  text-align: left;
  font-weight: lighter;
  font-family: "Titillium Web", sans-serif;
  padding: 0 0 15px 0;
  color: #f77200;
}

.privacy-policy-page-body p,
.privacy-policy-page-body li,
.refund-policy-page-body p,
.refund-policy-page-body li,
.terms-of-use-page-body p,
.terms-of-use-page-body li,
.clinical-validation-page-body p,
.clinical-validation-page-body li {
  text-align: justify;
  font-size: 14px;
  font-weight: normal;
  line-height: 23px;
}
.privacy-policy-page-body ol,
.terms-of-use-page-body ol,
.refund-policy-page-body ol,
.clinical-validation-page-body ol {
  padding-left: 20px;
}
.privacy-policy-page-body li li,
.terms-of-use-page-body li li,
.refund-policy-page-body li li,
.clinical-validation-page-body li li {
  list-style-type: lower-alpha;
}
.privacy-policy-page-body li li li,
.terms-of-use-page-body li li li,
.refund-policy-page-body li li li,
.clinical-validation-page-body li li li {
  list-style-type: lower-roman;
}

.privacy-policy-page-body li,
.terms-of-use-page-body li,
.refund-policy-page-body li {
  list-style-type: numeric;
}
.privacy-policy-page-body p span,
.terms-of-use-page-body p span,
.refund-policy-page-body p span {
  color: rgb(65, 131, 253);
  font-style: italic;
}

/* media query */
@media only screen and (max-width: 1920px) {
  .sections,
  .section-row1 {
    height: 1000px;
  }
  .section-about-us {
    height: fit-content;
  }
}
@media only screen and (max-width: 1368px) {
  .sections,
  .section-row1 {
    height: 600px;
  }
  .section-about-us {
    /* height: 650px; */
  }
}
@media only screen and (max-width: 1200px) {
  .tooltip {
    margin-left: 20px;
  }
  .card-prev {
    left: 5%;
  }
  .card-next {
    right: 5%;
  }
  .Industry-prev {
    left: 20%;
    top: 22%;
  }
  .Industry-next {
    left: 20%;
  }
}
/* for small Laptop 1024 */

@media only screen and (max-width: 1024px) {
  .tooltips {
    margin-left: 30px;
  }
  .header-bottom {
    width: 100%;
    left: 9%;
  }
  .coreValueModelLeft,
  .coreValueModelRight {
    padding: 0;
  }
  .industry-head-para br {
    display: none;
  }
  .col2-text4 {
    width: 25%;
  }
  .banner-text h1 {
    text-align: left;
    font-size: 35px;
    font-weight: 500;
    font-family: "Muli", sans-serif;
    letter-spacing: 4px;
    padding: 0 0 10px 0;
    opacity: 0.8;
    line-height: 50px;
  }
  .social-icon-container ul {
    width: 25%;
  }
}
@media only screen and (max-width: 900px) {
  .containerTestimonial {
    padding: 0;
  }
  .testimonial-mySlides {
    padding: 0;
  }
  .testi-slideshow-container {
    margin: 20% 0;
  }
  .header-bottom {
    width: 60%;
  }
  .industry-icon {
    width: 18%;
  }
  .client-slide div {
    margin: 10px;
  }
}
@media only screen and (max-width: 800px) {
  .sections {
    height: auto;
  }
  .section-row1 {
    height: auto;
  }
  .section-about-us {
    height: auto;
    padding: 50px 0;
  }
  .section-about-us br {
    display: none;
  }
  .header-bottom {
    width: 50%;
    left: 0%;
  }
  #header-bottom-1,
  #header-bottom-2,
  #header-bottom-3 {
    left: 0%;
    float: left;
    top: 35%;
  }
  .banner-text h1 {
    font-size: 30px;
    line-height: 36px;
    text-align: left !important;
  }
  .banner-text {
    width: 100%;
    margin: 0px 0 0 0;
  }
  .section4-content {
    margin: 0;
  }
  .resIcon {
    display: block;
    cursor: pointer;
  }
  .header-nav nav {
    display: none;
  }
  .header-bottom {
    width: 80%;
    margin: 0px 96px;
  }
  .testi-slideshow-container {
    margin: 0% 0;
  }
  .testi-mySlides-p p br {
    display: none;
  }
  .containerx {
    width: 100%;
    margin: 30px 0;
  }
  .about-us-slidetext {
    width: 100%;
  }
  .containerx img {
    width: 60%;
  }
  .testi-mySlides-p {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
  /* .testi-mySlides-p h3,
  p {
    text-align: center;
  } */
  /* .testi-mySlides-p p {
    text-align: center;
  } */
  #section2-testi-dot-container {
    width: 30%;
    left: 35%;
    top: 98%;
  }
  .stats-block {
    width: 100%;
    margin: 15px 0 0 0;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid #eca11759;
  }
  .prev {
    left: 5%;
    top: 95%;
  }
  .next {
    right: 5%;
    top: 95%;
  }
  .service-dot-container {
    right: 42%;
  }
  .section5-text {
    padding: 0 0 0 10px;
  }
  .section3-subject {
    padding: 0 0 0 20px;
  }
  q {
    font-size: 15px;
  }
  /* Menu Botton */
  .header-nav {
    float: left;
    text-align: center;
    width: 30%;
  }
  .header-top .header-logo {
    float: right;
    text-align: center;
    width: 60%;
  }
  .stickymenu {
    opacity: 0.9;
  }
  #menu {
    left: 1px;
    text-align: center;
    opacity: 1 !important;
  }
  /* testimonial */
  .job-testimonial-left {
    margin: 0;
    width: 100%;
    text-align: center;
    transform: translate(0);
  }
  .team-other-container ul {
    flex-direction: column;
  }
  .team-other-container .social-media-icon {
    flex-direction: row;
  }
  .advisory-container {
    flex-direction: column;
    padding: 30px 0;
  }
  .advisory-tag {
    width: 100%;
  }
  .advisory-container h3 {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .advisory-button {
    float: none;
  }

  /* contact us */
  .social-icon-container ul {
    width: 30%;
  }

  .col1-left {
    width: 100%;
  }
  .col2 {
    width: 100%;
    float: left;
    padding: 0;
  }
  .col2-text3,
  .col2-text2 {
    width: 100%;
    padding: 20px 0;
  }
  #col2-email-p {
    width: 80%;
  }
  .site-testimonial-left {
    width: 60%;
    margin: 20px 20%;
  }
  .site-testimonial-right {
    width: 60%;
    margin: 20px 20%;
    display: none;
  }
  .section-Testimonial {
    height: auto;
  }
  .header-logo .logo-img {
    margin: 10px 130px;
  }
  .site-testimonial {
    margin: 0;
  }
  .client-slide div {
    margin: 15px -12px;
  }
  .rhex .rhex-img {
    top: -20%;
  }
  .rhex {
    transform: scale(0.8);
  }
  #searched-job-id {
    transform: translate(41%, 1080%);
  }
}
/* for Tablate 768  */
@media only screen and (max-width: 768px) {
  .site-testimonial-left {
    padding: 20px;
  }
  .client-slide div {
    margin: 15px -12px;
  }
  .rhex .rhex-img {
    top: -20%;
  }
  .rhex {
    transform: scale(0.8);
  }
}
@media only screen and (max-width: 650px) {
  .testi-slideshow-container {
    margin: 0% 0;
  }
  .Industry-Right-cardM {
    width: 70%;
  }
  .logo-img2 {
    float: right;
  }
  .social-icon-container ul {
    width: 40%;
  }
  .col1 .col1-email {
    margin-top: 6%;
  }
  .col1-email h1 {
    margin-left: -142px;
  }

  .col2-text2,
  .col2-text3 {
    float: none;
  }
  .socialiconlist {
    width: 100%;
  }
  .social-icon1 {
    display: block;
  }
  .social-icon2 {
    display: inline-block;
  }
  .social-icon2 li {
    width: 10%;
    float: right;
    margin-left: 1px;
    text-align: center;
  }
  .social-icon2 li img {
    width: 80%;
  }
  .bottom-line {
    visibility: visible;
    width: 225px;
  }
  #copyright {
    font-size: 9px;
  }
  #mobile-view-col2-email {
    display: block;
    width: 40%;
  }
  #desktop-view-col2-email {
    display: none;
  }
  .client-slide {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 500px) {
  .table-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .table-clinical-validation {
    width: 95%;
    /* height: auto; */
    /* display: flex;
    justify-content: center; */
    /* align-items: center; */
  }

  .table-clinical-validation table {
    border: 1px solid black;
    width: 70px;
    height: 200px;
  }

  .table-clinical-validation th {
    border-bottom: 1px solid black;
    padding-left: 5px;

    color: #f77200;
  }

  td {
    text-align: center;
    padding-left: 5px;
  }
  .containerx {
    text-align: center;
  }
  q {
    font-size: 30px;
  }
  #header-bottom-1,
  #header-bottom-2,
  #header-bottom-3 {
    left: 0%;
    float: left;
    top: 35%;
  }
  .banner-text h1 {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1rem !important;
    margin-top: -10px;
    font-weight: bold;
    transform: translateX(-20%);
  }
  #Mu-contact-us-box {
    width: 300px;
  }
  /* about us */

  .testi-slideshow-container {
    margin: 0% 0;
  }
  .containerx img {
    width: 100%;
  }
  #About-Us {
    padding: 50px 0;
  }
  #Sign-In h1 {
    font-size: 35px;
  }
  .col2 h3 {
    font-size: 15px;
  }
  /* services */
  .card-next,
  .card-prev {
    top: 60%;
  }
  .cardM {
    width: 50%;
    margin: 0 25%;
  }
  /* subservices */
  .cardSubService {
    height: 1350px;
  }
  .extendedServiceList {
    top: 8%;
    left: 10%;
    right: 10%;
  }
  .subCard {
    width: 80%;
  }
  #back {
    top: 95%;
  }
  /*  */
  .job-testimonial-left {
    margin: 0;
    width: 100%;
  }
  .site-testimonial {
    margin: 0;
  }
  .rhex {
    transform: scale(0.7);
  }
  .client-slide div {
    margin: 20px 0px;
  }
  .section-Clients {
    height: auto;
  }
  #searched-job-id {
    transform: translate(41%, 1023%);
  }
  .header-logo .logo-img {
    margin: 10px 10px;
  }
  .social-icon-container ul {
    width: 50%;
  }
}
/* for mobiles L 425 */
@media only screen and (max-width: 430px) {
  .job-testimonial-left {
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  /* .col2-ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
  } */
  .header-logo .logo-img {
    margin: 10px;
  }
  #Mu-contact-us-box {
    width: 250px;
  }
  h1 {
    font-size: 25px;
  }
  h4 {
    font-size: 18px !important;
  }
  p {
    font-size: 15px;
  }

  .site-testimonial-left {
    width: 92%;
    margin: 20px 5%;
  }
}
@media only screen and (max-width: 360px) {
  .coreValueModelLeft img {
    left: 79%;
  }
  .coreValueModelRight img {
    right: 79%;
  }
  #About-Us {
    padding: 20px;
  }
}

.company_name {
  width: 100%;
  color: #212121;
  margin: 10px 0px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 4px;
  font-family: "Titillium Web", sans-serif;
  line-height: 1.7em;
}

.product_about {
  text-align: justify;
  font-size: 24px;
  color: #000;
  font-family: "Muli", sans-serif;
  /* line-height: 1.65em; */
  margin: 20px 0;
}

.demo_btn {
  width: 100%;
  padding: 15px 0;
  margin: 20px 0;
  font-size: 20px;
  background-color: rgb(0, 0, 0);
  color: #efefef;
  letter-spacing: 0.5rem;
}

.demo_btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.tab-list {
  margin: 30px 0;
  /* border-bottom: 1px solid #ccc; */
  padding-left: 0;
  letter-spacing: 0.2rem;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 1.5rem;
  font-size: 18px;
  cursor: pointer;
}

.tab-list-active {
  /* background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px; */
  color: #f77400;
}

.feature_points {
  text-align: justify;
  color: #212121;
  /* margin: 10px 0px; */
  font-size: 18px;
  /* font-weight: bold; */
  letter-spacing: 2px;
  font-family: "Titillium Web", sans-serif;
  /* line-height: 1.7em; */
}
